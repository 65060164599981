import React from "react";
import { Control, RegisterOptions, useController, UseFormSetValue } from "react-hook-form";
import {
  AddFileButton as StyledAddFileButton,
  AddFileButtonDescription,
  FileInfoItem,
  FilesInfo,
} from "./addFileButton.styles";
import toBase64 from "../../../core/utils/toBase64";
import { ReactComponent as CloseIcon } from "../../../core/static/icons/close.svg";
import AppFile from "../../../core/interfaces/File";

const AddFileButton: React.FC<
  {
    children: React.ReactNode;
    name: string;
    control: Control<any>;
    setValue: UseFormSetValue<any>;
    description?: string;
    accept?: string;
    multiple?: boolean;
    options?: RegisterOptions;
    educationLevelWatch?: string;
    previewProps?: {
      whiteBackground?: boolean;
      style?: React.CSSProperties;
    };
    buttonProps?: {
      whiteBackground?: boolean;
      style?: React.CSSProperties;
    };
  } & React.HTMLAttributes<HTMLDivElement>
> = ({
  children,
  accept,
  multiple = false,
  name,
  control,
  setValue,
  description,
  options = {},
  previewProps,
  buttonProps,
  ...attrs
}) => {
  const fileInputElement = React.useRef<HTMLInputElement>(null);
  const {
    field,
    fieldState: { error },
  } = useController({ control, name, rules: options });
  const [files, setFiles] = React.useState(field.value ?? []);

  const convertFilesList = async (data: FileList): Promise<(string | ArrayBuffer)[]> => {
    const promises: Promise<string | ArrayBuffer>[] = [];

    Array.from(data).forEach((file) => {
      promises.push(toBase64(file));
    });

    return await Promise.all(promises);
  };

  const onChangeHandler = async (data: FileList): Promise<void> => {
    setFiles([]);

    if (data && Array.from(data)?.length) {
      const base64Arr = await convertFilesList(data);

      setFiles(
        base64Arr.map((base64, index) => ({
          file: base64,
          info: {
            name: data[index].name,
            lastModified: data[index].lastModified,
            type: data[index].type,
            size: data[index].size,
          },
        }))
      );
    }
  };

  const openFileDialog = (): void => {
    if (fileInputElement) {
      fileInputElement.current.click();
    }
  };

  const removePhoto = (): void => {
    setFiles([]);
    fileInputElement.current.value = "";
    setValue(name, "");
  };

  React.useEffect(() => {
    field.onChange(files);
  }, [files]);

  return (
    <div className="addFileButton" {...attrs}>
      <input
        ref={fileInputElement}
        type="file"
        accept={accept}
        multiple={multiple}
        hidden
        onChange={(e: React.ChangeEvent<HTMLInputElement>): Promise<void> =>
          onChangeHandler(e.target.files)
        }
      />
      {field?.value?.length ? (
        <FilesInfo>
          <FileInfoItem
            style={{
              padding: `5px 10px 5px ${41 + field.value.length * 5}px`,
              ...previewProps?.style,
            }}
            whiteBackground={previewProps?.whiteBackground}
          >
            {field.value.map((file: AppFile, index: number) => (
              <div
                key={file?.info?.name}
                className="img"
                style={{ backgroundImage: `url("${file.file as string}")`, left: 8 + index * 5 }}
              />
            ))}
            <CloseIcon type="button" onClick={removePhoto} />
          </FileInfoItem>
        </FilesInfo>
      ) : (
        <StyledAddFileButton
          type="button"
          onClick={openFileDialog}
          hasErrors={!!error}
          whiteBackground={buttonProps?.whiteBackground}
          style={buttonProps?.style}
        >
          {children}
          {!!description && (
            <>
              <br />
              <AddFileButtonDescription>{description}</AddFileButtonDescription>
            </>
          )}
        </StyledAddFileButton>
      )}
    </div>
  );
};

export default AddFileButton;
