import React from "react";
import { Control, FieldValues, FormState, UseFormSetValue, useWatch } from "react-hook-form";
import { FormContainer } from "../../../../../../../shared/form/form.styles";
import {
  FieldDescription,
  FieldError,
  FieldInfo,
  FieldName,
} from "../../../../../../../shared/form/fieldInfo.styles";
import { FieldData } from "../../../../../../../shared/form/fieldData.styles";
import Select from "../../../../../../../shared/select/Select";
import { SplitAdditionalEducationForm } from "../../interfaces/additionalEducationForm";
import { useAppSelector } from "../../../../../../../../core/hooks/redux";
import AddFileButton from "../../../../../../../shared/addFileButton/AddFileButton";
import getSelectedAdditionalEducationItem from "../../utils/getSelectedAdditionalEducationItem";
import TypesDictionary from "../../../../../../../../core/interfaces/dictionaries/additionalEducationDictionaries/TypesDictionary";
import SelectItem from "../../../../../../../shared/select/SelectItem";
import AddFileInfo from "../../../../../../../shared/addFileInfo/AddFileInfo";

const PaymentForm: React.FC<{
  formState: FormState<FieldValues>;
  control: Control;
  setValue: UseFormSetValue<SplitAdditionalEducationForm>;
}> = ({ formState: { errors }, control, setValue }) => {
  const [selectedForm, setSelectedForm] = React.useState<TypesDictionary>(null);
  const { dictionariesState } = useAppSelector((state) => state.coreState);
  const [isRequiredAddFileButton, setIsRequiredAddFileButton] = React.useState({ required: false });
  const { additionalEducationState } = useAppSelector(
    (state) => state.modulesState.applicationsState
  );
  const educationLevelWatch = useWatch({ control, name: "discountsAndBenefits" });
  React.useEffect(() => {
    if (
      !additionalEducationState.application.program ||
      !additionalEducationState.application.form
    ) {
      return;
    }

    setSelectedForm(
      getSelectedAdditionalEducationItem(
        "form",
        additionalEducationState.application.level,
        additionalEducationState.application.program,
        additionalEducationState.application.form
      ) as TypesDictionary
    );
  }, [additionalEducationState.application.program, additionalEducationState.application.form]);

  React.useEffect(() => {
    setIsRequiredAddFileButton({ required: educationLevelWatch !== "0" });
  }, [educationLevelWatch]);

  return (
    <FormContainer>
      <FieldInfo>
        <FieldName>Вариант оплаты</FieldName>
        {errors.paymentMethod && <FieldError>{errors.paymentMethod?.message as string}</FieldError>}
      </FieldInfo>
      <FieldData>
        <Select
          className="defaultSelect"
          placeholder="Выберите вариант оплаты"
          items={
            selectedForm
              ? selectedForm.finances.map(
                  (financesItem) =>
                    ({
                      label: financesItem.finance,
                      value: financesItem.finance_id,
                    } as SelectItem)
                )
              : []
          }
          control={control}
          name="paymentMethod"
          options={{
            required: "Обязательное поле",
          }}
        />
      </FieldData>
      <FieldInfo>
        <FieldName>Скидки и льготы</FieldName>
        {errors.discountsAndBenefits && (
          <FieldError>{errors.discountsAndBenefits?.message as string}</FieldError>
        )}
      </FieldInfo>
      <FieldData>
        <Select
          className="defaultSelect"
          placeholder="Выберите льготу"
          items={[
            { name: "Нет", id: "0" },
            ...dictionariesState.additionalEducationDictionaries.discounts,
          ].map((discount) => ({
            label: discount.name,
            value: discount.id,
          }))}
          control={control}
          name="discountsAndBenefits"
          options={{
            required: "Обязательное поле",
          }}
        />
      </FieldData>
      <FieldInfo>
        <FieldName>Загрузка документа</FieldName>
        <FieldDescription>без документа скидка не будет применена</FieldDescription>
        {errors.discountConfirmation && (
          <FieldError>{errors.discountConfirmation?.message as string}</FieldError>
        )}
      </FieldInfo>
      <FieldData>
        <AddFileButton
          name="discountConfirmation"
          control={control}
          setValue={setValue}
          accept={"image/*"}
          multiple
          options={isRequiredAddFileButton}
        >
          Подтверждение скидки
        </AddFileButton>
      </FieldData>
      <FieldInfo />
      <FieldData>
        <AddFileInfo />
      </FieldData>
    </FormContainer>
  );
};

export default PaymentForm;
